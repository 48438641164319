.cases-title {
    font-size: 72px;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    margin-top: 24px;
}

.cases-title-meta {
    font-size: 24px;
    margin-top: 24px;
    color: #666666;
    text-align: center;
}

@media screen and (max-width: 991px) {
    .cases-title {
        font-size: 48px;
        margin-top: 16px;
    }

    .cases-title-meta {
        font-size: 18px;
        margin-top: 16px;
    }
}
