.layout {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    scroll-behavior: smooth;
}

.landing-page {

}

.landing-page-noscroll {
    overflow-y: hidden;
}

.hero {
    width: 100vw;
    /*height: 500px;*/
    position: relative;
    background-image: url("../images/background-image.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-no-background {
    background-image: none;
}

.background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.background-gradient {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.45) 55%, rgb(255, 255, 255) 100%);
    z-index: 1;
}

.hero-content {
    padding: 30px 0;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.hero-content-inner-container {
    margin: 20px auto;
}

.hero-text-container {
    margin: 40px;
}

.hero-text {
    font-size: 72px;
    text-align: center;
    letter-spacing: -0.02em;
    line-height: 115%;
    font-weight: 900;
    color: rgb(17, 17, 17);
    max-width: 1136px;
}

@media screen and (max-width: 768px) {
    .hero-content {
        padding: 0;
    }

    .hero-text-container {
        margin: 0;
    }

    .hero-text {
        font-size: 34px;
        letter-spacing: -0.01em;
    }
}

.hero-btn-container {
    margin: 40px 0;
    display: flex;
}

.hero-btn-service {
    margin: auto 20px auto auto;
}

.story-btn-service {
    margin: auto;
}

.hero-btn-work {
    margin: auto auto auto 20px;
}

.see-btn-container {
    margin: auto;
}

.hero-btn-inner-container {
    box-sizing: border-box;
    min-width: 0;
    -webkit-text-decoration: none;
    text-decoration: none;
}

.hero-btn-inner-link {
    display: inline-block;
    -webkit-text-decoration: none;
    text-decoration: none;
}

.hero-btn {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 23px;
    line-height: 34px;
    padding: 12px 40px;
    border: none;
    border-radius: 58px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    -webkit-transition: background-color 0.18s, box-shadow 0.18s, border-color 0.18s, color 0.18s;
    transition: background-color 0.18s, box-shadow 0.18s, border-color 0.18s, color 0.18s;
    z-index: 10;
}

.hero-btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.hero-btn:active {
    box-shadow: none;
}

@media screen and (max-width: 768px) {
    .hero-btn-service {
        margin: auto;
    }

    .hero-btn-work {
        display: none;
    }

    .hero-btn {
        font-size: 20px;
        height: 50px;
    }
}

.hero-service-btn {
    color: #111;
    background-color: #18ECC0;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
}

.hero-service-btn:hover {
    border-color: #111;
    color: #FFF;
    box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.25);
}

.hero-work-btn {
    color: white;
    background-color: black;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
}

.hero-work-btn:hover {
    border-color: #111;
    color: black;
    box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.25);
}

.see-btn {
    color: black;
    background-color: white;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
}

.see-btn:hover {
    border-color: white;
    color: white;
    box-shadow: 0px 12px 24px rgba(17, 17, 17, 0.25);
}

.hero-btn-div {
    position: absolute;
    width: 150%;
    height: 200%;
    border-radius: 50%;
    top: -50%;
    left: -25%;
    -webkit-transform: translate(0, 75%);
    -ms-transform: translate(0, 75%);
    transform: translate(0, 75%);
    transition-duration: 0.3s;

}

.hero-service-btn-div {
    background-color: #111;
}

.hero-work-btn-div {
    background-color: white;
}

.see-btn-btn-div {
    background-color: black;
}

.hero-service-btn:hover .hero-service-btn-div {
    transform: translateY(0) translateZ(0px);
    transition-duration: 0.3s;
}

.hero-btn:hover .hero-btn-div {
    transform: translateY(0) translateZ(0px);
    transition-duration: 0.3s;
}

.hero-service-btn-text {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 1;
    transform: translateY(0%) translateZ(0px);
    transition: transform 0.2s ease-out;
}

.sponsor-container {
    padding-top: 128px;
    display: flex;
    z-index: 10;
}

.sponsor-sub-container {
    display: flex;
}

.sponsor-img {
    width: 140px;
    margin: auto 40px;
}

.sponsor-nike-img {
    width: 80px;
}

.sponsor-sm-text {
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #666666;
    text-align: center;
    display: none;
}

@media screen and (max-width: 1400px) {
    .sponsor-container {
        padding-top: 100px;
    }

    .sponsor-img {
        width: 120px;
        margin: auto 40px;
    }

    .sponsor-nike-img {
        width: 70px;
    }
}

@media screen and (max-width: 1200px) {
    .sponsor-container {
        padding-top: 80px;
    }

    .sponsor-img {
        width: 120px;
        margin: auto 20px;
    }

    .sponsor-nike-img {
        width: 70px;
    }
}

@media screen and (max-width: 992px) {
    .sponsor-container {
        padding-top: 100px;
    }

    .sponsor-img {
        width: 90px;
        margin: auto 20px;
    }

    .sponsor-nike-img {
        width: 50px;
    }
}

@media screen and (max-width: 768px) {
    .sponsor-sm-text {
        display: block;
    }

    .sponsor-container {
        padding-top: 0px;
    }

    .sponsor-sub-container {
        margin: auto;
        flex-direction: column;
    }

    .sponsor-img {
        text-align: center;
        width: 170px;
        margin-bottom: 30px;
    }

    .sponsor-nike-img {
        width: 90px;
    }
}

@media screen and (max-width: 576px) {
    .sponsor-sm-container {
        display: none;
    }
}

.story-container {
    width: 100vw;
    height: 500px;
    position: relative;
    background-image: url("../images/background-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.story-inner-container {
    position: relative;
    z-index: 2;
    margin: auto;
}

.story-title {
    font-size: 40px;
    font-weight: 900;
    text-align: center;
}

.story-text {
    font-size: 24px;
    text-align: center;
    color: #666666;
}
