.case-promo-container {
    position: relative;
}

.case-promo-container-1 {
    margin: 100px 0 300px 0;
}

.case-promo-container-2 {
    margin: 400px 0 50px 0;
}

.case-promo-container-3 {
    margin: 250px 0 100px 0;
}

@media screen and (max-width: 991px) {
    .case-promo-container-2 {
        margin: 800px 0 100px 0;
    }
}

@media screen and (max-width: 768px) {
    .landing-page-case-promo-container {
        margin: 0 0 300px 0;
    }

    .case-promo-container-2 {
        margin: 300px 0;
    }

    .case-promo-container-3 {
        margin: 50px 0 500px 0;
    }
}

.case-promo-image-container {
    width: 100%;
}

.case-promo-image {
    width: 100%;
    border-radius: 20px;
}

.case-promo-heading-meta {
    font-size: 20px;
    color: #666666;
}

@media screen and (max-width: 768px) {
    .case-promo-image {
        height: 250px;
    }
}

.case-promo-container:hover .case-promo-text-container {
    transition-duration: 0.3s;
    transform: translateY(-10px);
}

.case-promo-container:not(:hover) .case-promo-text-container {
    transition-duration: 0.3s;
    transform: translateY(0px);
}

.case-promo-text-container {
    position: absolute;
    background-color: white;
    border-radius: 20px;
    height: 600px;
    width: 480px;
    padding: 56px;
    display: flex;
    flex-direction: column;
}

.case-promo-text-container-1 {
    right: 5%;
    top: 11%;
}

.case-promo-text-container-2 {
    left: 0%;
    top: -20%;
}

.case-promo-text-container-3 {
    width: 800px;
    height: 550px;
    right: 0%;
    top: -20%;
}

@media screen and (max-width: 1200px) {
    .case-promo-text-container-3 {
        width: 600px;
        height: 600px;
        top: -40%;
    }
}

@media screen and (max-width: 991px) {
    .case-promo-text-container-1 {
        right: -10%;
        top: 25%;
    }

    .case-promo-text-container-2 {
        left: 0%;
        top: -80%;
    }

    .case-promo-text-container-3 {
        width: 500px;
        height: 600px;
        top: -40%;
    }

    .case-promo-text-container-3 > .case-promo-heading {
        font-size: 28px;
    }

    .case-promo-text-container-3 > .case-promo-features {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .case-promo-text-container-1 {
        right: 0%;
        left: 5%;
        width: 90%;
        top: 70%;
        padding: 32px;
        height: 336px;
    }

    .case-promo-text-container-2 {
        right: 0%;
        left: 5%;
        width: 90%;
        top: 70%;
        padding: 32px;
        height: 336px;
    }

    .case-promo-text-container-3 {
        right: 0%;
        left: 5%;
        width: 90%;
        height: 520px;
        top: 70%;
        padding: 32px;
    }
}


.case-promo-heading {
    font-size: 40px;
    font-weight: 900;
    line-height: 115%;
    margin: 15px 0 30px 0;
}

.case-promo-heading-dot {
    color: rgb(24, 236, 192);
    line-height: 0;
}

.case-promo-features {
    color: #666666;
    font-size: 24px;
}

.case-promo-features-dot {
    font-weight: 900;
}

.view-case-text {
    margin-top: auto;
    color: #666666;
    font-size: 20px;
}

.view-case-text-arrow {
    font-weight: 800;
}

@media screen and (max-width: 768px) {
    .case-promo-heading {
        font-size: 31px;
    }

    .case-promo-features {
        font-size: 16px;
    }

    .view-case-text {
        font-size: 18px;
    }
}