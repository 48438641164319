.services-text-container {
    margin-top: 50px;
    padding: 0 25%;
}

@media screen and (max-width: 1200px) {
    .services-text-container {
        padding: 0 15%;
    }
}


@media screen and (max-width: 991px) {
    .services-text-container {
        padding: 0 5%;
    }
}

@media screen and (max-width: 768px) {
    .services-text-container {
        margin-top: 0px;
        padding: 0;
    }
}

.services-heading {
    font-size: 64px;
    font-weight: 900;
    text-align: left;
    color: #111111;
    letter-spacing: -0.03em;
    line-height: 110%;
    margin-bottom: 40px;
}

.services-heading-text {
    font-size: 24px;
    color: #333131;
    margin-bottom: 40px;
}

.our-services-text {
    font-size: 64px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 80px;
}

.our-services-heading {
    font-size: 46px;
    font-weight: 900;
    margin-bottom: 24px;
}

.our-services-li {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 16px;
}


@media screen and (max-width: 768px) {

    .services-heading {
        font-size: 40px;
    }

    .services-heading-text {
        font-size: 18px;
    }

    .our-services-text {
        font-size: 40px;
        margin-bottom: 25px;
    }

    .our-services-heading {
        font-size: 40px;
        margin-bottom: 32px;
    }

    .our-services-heading {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .our-services-li {
        font-size: 18px;
    }
}

.our-partners-services {
    font-size: 26px;
    color: #666666;
    margin-bottom: 32px;
    text-align: center;
}

.service-partner-img {
    width: 120px;
}

.service-promo-case-img {
    width: 100%;
}

.service-promo-case-img > img {
    width: 100%;
    border-radius: 20px;
    height: 320px;
}

.service-promo-case-container {
    margin-bottom: 250px;
}

.case-study-card-services {
    color: rgb(32, 187, 155);
    font-size: 18px;
}

.card-services-heading-meta {
    font-size: 25px;
    font-weight: 900;
    margin: 15px 0;
}

.card-services-heading-text {
    font-size: 18px;
    margin: 0 0 40px 0;
    color: #666666;
}

.service-promo-case-card {
    background-color: white;
    border-radius: 20px;
    position: absolute;
    right: 0%;
    left: 10%;
    width: 80%;
    top: 60%;
    padding: 32px;
    height: 300px;
}
