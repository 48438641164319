.footer {
    background-color: white;
    padding: 100px 0;
    box-shadow: inset 0px 0px 20px 0 rgba(0, 0, 0, 0.15);
}

.footer-partner-img {
    width: 130px;
    margin: 0 0 50px 0;
}

.footer-title {
    font-size: 24px;
    color: #111111;
    margin: 0 0 20px 0;
    font-weight: 700;
}

.footer-text {
    font-size: 24px;
    margin-bottom: 10px;
    cursor: pointer;
}

.footer-icon {
    font-size: 25px;
    margin: auto 15px auto 0;
}

.below-footer-container {
    display: flex;
}

.below-footer-container-2 {
    display: flex;
    margin-left: auto;
}

.below-footer-text {
    font-size: 16px;
    color: #666666;
}

.footer-sm {
    display: none;
}

@media screen and (max-width: 768px) {
    .footer {
        background-color: white;
        padding: 40px 0;
    }

    .below-footer-container {
        display: block;
    }

    .footer-title {
        font-size: 22px;
    }

    .footer-text {
        font-size: 22px;
    }

    .sm-footer-text {
        margin-bottom: 30px;
    }

    .footer-xl {
        display: none;
    }

    .footer-sm {
        display: block;
        margin-top: 20px;
    }

    .footer-partner-img {
        width: 120px;
        margin: 0 20px 20px 0;
    }
}

