.contact-us-body-container {
    display: flex;
}

.contact-us-body {
    margin: auto;
    max-width: 700px;
}

.contact-us-send-email-text {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0 64px;
}

.contact-us-send-email-text > a {
    color: #666666;
}

.contact-us-body-heading {
    font-size: 72px;
    font-weight: 900;
    color: #111111;
}

.contact-us-body-sub-heading {
    color: #666666;
    font-size: 24px;
    margin: 16px 0 0;
}

.contact-us-input-label {
    font-size: 20px;
    font-weight: 400;
    color: #666666;
}

.contact-us-input {
    background-color: #F5F5F5;
    padding: 10px 24px;
    border-radius: 5px;
    font-size: 18px;
    margin: 8px 0 24px;
    border: 0;
}

@media screen and (max-width: 768px) {

    .contact-us-body-heading {
        font-size: 40px;
    }

    .contact-us-body-sub-heading {
        font-size: 18px;
    }

    .contact-us-input-label {
        font-size: 16px;
    }
}

.contact-info-heading {
    font-size: 32px;
    font-weight: 900;
    margin: 16px 0;
    color: #111111;
}

.contact-info-text {
    font-size: 24px;
    margin: 10px 0;
    color: #666666;
}


.swiper {
    width: 100%;
    height: 100%;
}

.slider-img {
    min-width: 400px !important;
    border-radius: 10px;
}

.swiper-container {
    height: calc(100vh - 120px);
    margin: 60px;
}

.swiper-slide {
    width: 28vw;
    overflow: hidden;
    background-position: top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-right: 40px !important;
    transform: translateY(0);
    transition-duration: 2s;
    transition-delay: 1.5s;
}

.swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}

@media screen and (max-width: 1200px) {
    .swiper-slide {
        width: 350px;
    }
}

.swiper-slide-prev {
    transform: translateY(-5px) !important;
    transition-duration: 2s;
    transition-delay: 1.5s;
}

.swiper-slide-active {
    transform: translateY(-5px) !important;
    transition-duration: 2s;
    transition-delay: 1.5s;
}

.swiper-slide-next {
    transform: translateY(-5px) !important;
    transition-duration: 2s;
    transition-delay: 1.5s;
}
