.navbar {
    display: flex;
}

.navbar-logo {
    width: 75px;
    margin: auto auto auto 0;
}

.navbar-links {

}

.navbar > p {
    color: black;
}

.navbar-links-container {
    display: flex;
}

.navbar-links-container > * {
    margin: auto 0 auto 40px;
}

.navbar-links-container > p {
    font-size: 21px;
    position: relative;
    line-height: 25px;
    color: rgb(17, 17, 17);
    transition: color 0.2s ease 0s;
    text-decoration: none;
    cursor: pointer;
}


.navbar-links-container > p::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(24, 236, 192);
    transform-origin: center;
    transition: transform 0.25s ease-out;
}

.navbar-links-container > p:hover::after {
  transform: scaleX(1);
  transform-origin: center;
}

.navbar-links-container-separator {
    box-sizing: border-box;
    margin: auto 0 auto 32px;
    text-decoration: none;
    width: 1px;
    height: 16px;
    background-color: rgb(153, 153, 153);
}

.navbar-sm-item > * {
    display: none;
}

@media screen and (max-width: 991px) {
    .navbar-lg-item > * {
        display: none;
    }

    .navbar-sm-item > * {
        display: block;
    }
}

.navbar-sm {
    display: flex;
    border-bottom: 1px solid rgb(229, 229, 229);
}

.navbar-sm-logo-container {
    padding: 10px;
    border-right: 1px solid rgb(229, 229, 229);
    margin: auto auto auto 0;
}

.navbar-sm-ham-container {
    padding: 10px;
    border-left: 1px solid rgb(229, 229, 229);
    margin: auto 0;
}

.navbar-sm-logo {
    width: 32px;
}

.hamburger-icon {
    font-size: 30px;
}

.side-panel {
    position: absolute;
    z-index: 99;
    height: 100vh;
    right: 0;
    overflow-y: hidden;
}

.side-panel-active {
    width: 100vw;
    transition: 0.3s ease-in-out;
    overflow-x: hidden;
}

.side-panel-inactive {
    width: 0;
}

.side-panel-background {
    background-color: black;
    opacity: 80%;
    width: 100vw;
    height: 100vh;
}

.side-panel-inner-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.display-item-none {
    display: none;
}

.sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    z-index: 1000;
    background-color: white;
}

.sidebar-div {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid rgb(229, 229, 229);
}

.sidebar-div-heading-text {
    font-size: 16px;
    color: #666666;
    font-weight: 600;
    margin: auto 0;
}

.sidebar-div-text {
    font-size: 20px;
    color: #666666;
    margin: auto 0;
    cursor: pointer;
}

.sidebar-div-text:hover {
    color: #333333;
}
